<template>
  <div>
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      title="Feedback"
    />
    <span class="ma-0">
      Please <a href="mailto:feedback@doestaffportal.com">click here</a> for sending your feedback.
    </span>
  </div>
</template>
<script>
  import appBarMobile from "@/components/appBarMobile";
  export default {
    components: {
      appBarMobile,
    }
  }
</script>

<style scoped>

</style>